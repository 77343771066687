import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";

import Welcome from "./Welcome";
import LoginForm from "./LoginForm";
import SliderComponent from "./SliderComponent";

import Main from "../../pages/Main";

import { BASE_URL } from "../../config/API";
import { getRequestHeaders } from "../../utils/functions";


const Login = () => {
  const initialState = {email: "", password: "", way: "email_password"};

  const history = useHistory();

  const token = localStorage.getItem("token");

  const [userLoginData, setUserLoginData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [auth, setAuth] = useState(!!token);

  const sendRequest = async (body) => {

    const requestOptions = getRequestHeaders("POST", JSON.stringify(body));

    try {
      const fetchData = await fetch(`${BASE_URL}/login/`, requestOptions);
      const response = await fetchData.json();

      if(!!response && !!response.data && !response.data.token) {
        return response.message;
      }

      return response;
    } catch(e) {
        throw e;
    }
  };

  const inputHandler = (event) => {
    event.persist()
    setUserLoginData(prev => ({...prev, [event.target.name]: event.target.value}));
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const responseData = await sendRequest(userLoginData);

    if(!!responseData && !!responseData.data && !!responseData.data.token) {
      setAuth(true);
      setErrors({});
      localStorage.setItem("token", responseData.data.token);
      localStorage.setItem("email", userLoginData.email);
      history.push("/");
    } else {
      if(responseData.hasOwnProperty("errors")) {
        setErrors(responseData.errors)
      }

      setAuth(false)
    }
  };

  useEffect(() => {
    if (token === null) {
      setAuth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      { auth
      ?
        ( <Main /> )
      : (
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <SliderComponent />
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <div className="app-logo" />
                <Welcome />
                <Row className="divider" />
                <LoginForm submitHandler={submitHandler} inputHandler={inputHandler} errors={errors} />
              </Col>
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
};

export default Login;
