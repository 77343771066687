import { $delete, $get, $post } from "../functions";


export const getAnnouncementsRequest = (token, data = {}) =>
    $get(`announcements`, data, {token});

export const getAnnouncementsTypeRequest = (token, data = {}) =>
    $get(`announcements-type`, data, {token});

export const removeAnnouncementRequest = (currentId, token, data = {}) =>
    $delete(`announcements/${currentId}`, data, {token});

export const addAnnouncementRequest = (token, data = {}) =>
    $post(`announcements/`, data, {token});
