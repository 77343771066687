import { $delete, $get, $post } from "../functions";


export const getCompetitors = (token, data = null) =>
    $get(`competitors`, data, {token});

export const createCompetitors = (token, data = null) =>
    $post(`competitors`, data, {
        token,
        formData: true,
        headers: {"Accept": "application/json"}
    });

export const removeCompetitor = (id, token, data = null) =>
    $delete(`competitors/${id}`, data, {token});

export const editCompetitor = (id, token, data = null) =>
    $post(`competitors/${id}`, data, {
        token,
        formData: true,
        headers: {"Accept": "application/json"}
    });

