export const CONTEST_TAB_NAMES = {
    ATTENTION_REQUIRED: "Attention Required",
    LIVE: "Live",
    ENDS_TODAY: "Ends Today",
    // SPORT: "Sport",
    DATE: "By Date",
    BASEBALL: "Baseball",
    BASKETBALL: "Basketball",
    FOOTBALL: "Football",
    GOLF: "Golf",
    HOCKEY: "Hockey",
    SOCCER: "Soccer",
    NCAA: "NCAA"
};
