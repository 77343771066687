import { $delete, $get, $post, $put } from "../functions";


export const getEvents = (eventId, data = null, token) =>
    $get(`events/${eventId}`, data, {token});

export const getAllEvents = (data, token) =>
    $get("events", data, {token});

export const setNewEvent = (data, token) =>
    $post("events", data, {token});

export const updateEvent = (eventId, data = null, token) =>
    $put(`events/${eventId}`, data, {token});

export const removeEvent = (eventId, token) =>
    $delete(`events/${eventId}`, null, {token});
