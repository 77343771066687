export const entityForGroups = [
    {value: "", name: "Type to select Entity type...", endPoint: ""},
    {value: "App\\Models\\City", name: "City", endPoint: "cities"},
    {value: "App\\Models\\Country", name: "Country", endPoint: "countries"},
    {value: "App\\Models\\County", name: "County", endPoint: "counties"},
    {value: "App\\Models\\State", name: "State", endPoint: "states"},
    {value: "App\\Models\\Competition", name: "Competition", endPoint: "competitions"},
    {value: "App\\Models\\Competitor", name: "Competitor", endPoint: "competitors"},
    {value: "App\\Models\\College", name: "College", endPoint: "colleges"},
];
