export const FIELDS_RANKS = {
    NAME: "name",
    START_RANGE: "start_range",
    END_RANGE: "end_range",
    MULTIPLIER: "multiplier",
    PARENT: "parent",
    IS_PARENT: "is_parent",
    IS_SUB_RANK: "is_sub_rank"
};

export const PARENT_OPTIONS = [
    {value: "false", name: "No"},
    {value: "true", name: "Yes"}
];

export const PARENT_OPTIONS_SUB = [
    {value: "false", name: "Yes"},
    {value: "true", name: "No"}
];
