import { useEffect } from "react";

export function useAbortableEffect(effect, dependencies) {
    const status = {};
  
    useEffect(() => {
        status.aborted = false;

        const cleanUpFn = effect(status);

        return () => {
            status.aborted = true;
            if (typeof cleanUpFn === "function") {
                cleanUpFn();
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies]);
};
