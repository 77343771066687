import { $get, $post, $delete } from "../functions";


export const getEventQuestions = (eventId, data = null, token) =>
    $get(`events/${eventId}/questions`, data, {token});

export const getEventQuestion = (eventId, questionId, token, data = null) =>
    $get(`events/${eventId}/questions/${questionId}`, data, {token});

export const setEventQuestionGrade = (eventId, questionId, data, token) =>
    $post(`events/${eventId}/questions/${questionId}/grade`, data, {token});

export const removeQuestion = (eventId, questionId, token, data = null) =>
    $delete(`events/${eventId}/questions/${questionId}`, data, {token});

export const getAllQuestions = (token, data = null) =>
    $get(`questions`, data, {token});

export const setQuestionRequest = (id, token, data = null) =>
    $post(`events/${id}/questions`, data, {token});

export const getCurrentEvent = (eventId, data = null, token) =>
    $get(`events/${eventId}`, data, {token});
