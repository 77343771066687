export const CONTEST_TYPES_KEYS = {
    NAME: "name",
    QUESTION_COUNT: "number_of_questions",
    COUNTDOWN: "question_countdown",
    TIE_BREAK: "use_tie_break_question",
    RANK: "ranks",
    ENTREE_FEE: "entry_fee",
    GROUP_SIZE: "max_players",
    PRIZING: "prizing",
    ENTRY_BADGES: "badge",
    SELECT_BADGES: "reward",
    TOP_FINISHERS: "top_finisher_awards",
    TYPE: "type",
    SELECT_SPORT: "sport",
    SELECT_PLACE: "place",
};

export const CONTEST_TYPES_INITIAL_STATE = {
    [CONTEST_TYPES_KEYS.NAME]: "",
    [CONTEST_TYPES_KEYS.QUESTION_COUNT]: "3",
    [CONTEST_TYPES_KEYS.COUNTDOWN]: "3",
    [CONTEST_TYPES_KEYS.TIE_BREAK]: 0,
    [CONTEST_TYPES_KEYS.RANK]: [],
    [CONTEST_TYPES_KEYS.ENTREE_FEE]: "5",
    [CONTEST_TYPES_KEYS.GROUP_SIZE]: "25",
    [CONTEST_TYPES_KEYS.PRIZING]: "b25df5ef",
    [CONTEST_TYPES_KEYS.ENTRY_BADGES]: "0",
    [CONTEST_TYPES_KEYS.SELECT_BADGES]: [],
    [CONTEST_TYPES_KEYS.TOP_FINISHERS]: "0",
    [CONTEST_TYPES_KEYS.TYPE]: "daily",
    [CONTEST_TYPES_KEYS.SELECT_PLACE]: "1"
};

export const contestType = [
    {value: "", name: "Type to select Contest Type..."},
    {value: "daily", name: "Daily"},
    {value: "weekly", name: "Weekly"},
];

export const TAB_NAMES = {
    VIEW_CONTEST_TYPE: "VIEW CONTEST TYPES",
    DAILY: "Daily",
    WEEKLY: "Weekly",
    SPORT: "Sport",
    TEAM: "Team",
    OTHER: "Other"
};

export const QUESTION_COUNT_OPTIONS = [
    {value: 3, name: "3Q"},
    {value: 6, name: "6Q"},
    {value: 9, name: "9Q"},
];

export const TIME_PER_QUESTION_OPTIONS = [
    {value: 3, name: "3 Secs"},
    {value: 5, name: "5 Secs"},
    {value: 7, name: "7 Secs"},
];

export const BOOLEAN_OPTIONS = [
    {value: 1, name: "Yes"},
    {value: 0, name: "No"},
];

export const ENTRY_FEE_OPTIONS = [
    {value: 5, name: "5 Bolts"},
    {value: 10, name: "10 Bolts"},
    {value: 25, name: "25 Bolts"},
];

export const GROUP_PRIZE_SIZE_OPTIONS = [
    {value: 25, name: "25"},
    {value: 50, name: "50"},
    {value: 100, name: "100"},
    {value: 200, name: "200"},
];

export const PRIZING_OPTIONS = [
    {value: "b25df5ef", name: "25 Bolts down for 5 entry fee"},
    {value: "b50df5ef", name: "50 Bolts down for 5 entry fee"},
    {value: "b100df5ef", name: "100 Bolts down for 5 entry fee"},
    {value: "b200df5ef", name: "200 Bolts down for 5 entry fee"},
    {value: "b25df10ef", name: "25 Bolts down for 10 entry fee"},
    {value: "b50df10ef", name: "50 Bolts down for 10 entry fee"},
    {value: "b100df10ef", name: "100 Bolts down for 10 entry fee"},
    {value: "b200df10ef", name: "200 Bolts down for 10 entry fee"},
    {value: "b25df25ef", name: "25 Bolts down for 25 entry fee"},
    {value: "b50df25ef", name: "50 Bolts down for 25 entry fee"},
    {value: "b100df25ef", name: "100 Bolts down for 25 entry fee"},
    {value: "b200df25ef", name: "200 Bolts down for 25 entry fee"},
];

export const TOP_FINISHERS = [
    {value: 0, name: "None"},
    {value: 5, name: "Top 5"},
    {value: 10, name: "Top 10"},
    {value: 50, name: "Top 50"},
    {value: 100, name: "Top 100"},
];

export const TOP_FINISHERS2 = [
    {value: 0, name: "None"},
    {value: 5, name: "Top 5"},
    {value: 10, name: "Top 10"},
    {value: 50, name: "Top 50"},
    {value: 100, name: "Top 100"},
];

export const TYPES = [
    {value: "daily", name: "Daily"},
    {value: "weekly", name: "Weekly"},
];
