export const FIELDS_COMPETITORS = {
    NAME: "name",
    SHORT_NAME: "short_name",
    TLA: "tla",
    SPORT_ID: "sport_id",
    COUNTRY_ID: "country_id",
    STATE_ID: "state_id",
    COUNTY_ID: "county_id",
    CITY_ID: "city_id",
    COLLEGE_ID: "college_id",
    IS_ENABLED: "is_enabled",
    COMPETITIONS: "competitions",
    SPORT: "sport",
    COUNTRY: "country",
    STATE: "state",
    COUNTY: "county",
    CITY: "city",
    AVATAR: "avatar",
};

export const SEASON  = [
    {value: "true", name: "Season"},
    {value: "false", name: "Off Season"},
];
