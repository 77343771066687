import { BASE_URL } from "../../config/API";

const METHODS = {
    GET: "GET",
    PUT: "PUT",
    PATCH: "PATCH",
    POST: "POST",
    DELETE: "DELETE"
};


function objectToQueryString(obj) {
    return (Object.keys(obj))
        .map(key => key + '=' + obj[key])
        .join('&');
}


const request = (method) => (baseUrl, data, config = {}) => {
    let url = `${BASE_URL}/${baseUrl}`;
    let options = {
        method,
        headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
        }
    };

    if (data) {
        if (method === METHODS.GET) {
            url += '?' + objectToQueryString(data);
        } else {
            if(!!config && config.hasOwnProperty('formData')) {
                options.body = data;
            } else {
                options.body = JSON.stringify(data);
            }
        }
    }

    if (config.hasOwnProperty("headers")) {
        options.headers = { ...options.headers, ...config.headers }
    }

    if (config.hasOwnProperty("token")) {
        options.headers = { ...options.headers, Authorization: `Bearer ${config.token}` }
    }

    if(!!config && config.hasOwnProperty('formData')) {
        delete options.headers["Content-Type"];
    }

    if(method === METHODS.DELETE) return fetch(url, {...options});
    return fetch(url, {...options}).then(response => response.json());
};


export const $get = request(METHODS.GET);
export const $put =  request(METHODS.PUT);
export const $post = request(METHODS.POST);
export const $patch = request(METHODS.PATCH);
export const $delete = request(METHODS.DELETE);
