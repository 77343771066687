import { $get } from "../functions";

export const getAllCountries = (token, data = null) =>
    $get(`countries`, data, {token});

export const getAllStates = (token, data = null) =>
    $get(`states`, data, {token});

export const getAllCounties = (token, data = null) =>
    $get(`counties`, data, {token});

export const getAllCities = (token, data = null) =>
    $get(`cities`, data, {token});
