export const QUESTIONS_MANAGE_TABLE_TAB_NAMES = {
    BY_DATE: "By Date",
    // DAY: "Day",
    SPORT: "Sport",
    COMPETITION: "Competition",
    // EVENT: "Event",
    TEAM: "Team",
    STATUS: "Status"
};

export const CREATE_QUESTION_STATE_KEYS = {
    EVENT_ID: "event_id",
    NAME: "name",
    FIRST_ANSWER: "first_answer",
    SECOND_ANSWER: "second_answer",
    DIFFICULTY: "difficulty",
    IS_AVAILABLE: "is_available_for_challenges",
    CATEGORY_ID: "category_id"
};

export const PARAM_NAMES = {
    DATE: "date",
    SPORT: "sport",
    NCAA_SPORT: "ncaa_sport",
    COMPETITION: "competition",
    NCAA_COMPETITION: "ncaa_competition",
    COMPETITOR: "competitor",
    EVENT: "event",
    TEAM: "team",
    WEEK: "week",
    COUNTRY: "country",
    CONTEST: "contest",
    STATUS: "status"
};

export const QUESTION_TABLE_TAB_NAMES = {
    READY_TO_GRADE: "Ready To Grade",
    UPCOMING: "Upcoming",
    LIVE_NOW: "Live Now",
    ENDING_SOON: "Ending Soon",
    DAILY_CONTEST: "Daily Contest",
    SPORT: "Sport",
    LEAGUE: "League",
    TEAM: "Team",
};

export const QUESTION_GRADE_TABLE_TAB_NAMES = {
    READY_TO_GRADE: "Ready To Grade",
    SPORT: "Sport",
    LEAGUE: "League",
    TEAM: "Team",
    CONTEST: "Contest",
    STATUS: "Status"
};

export const QUESTION_STATUSES = [
    {value: "in_use", label: "In Use"},
    {value: "unused", label: "Unused"},
    {value: "ended", label: "Ended"},
];
