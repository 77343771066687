import React, { Suspense, lazy, Fragment, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Loader from "react-loaders";
import { has, propEq, find } from "ramda";
import { ToastContainer } from "react-toastify";

import Login from "../../components/Login";
import Context from "../../context/Context";
import { useAbortableEffect } from "../../utils/functions";
import { getAllSports } from "../../utils/API";
import { SPORT_NAMES } from "../../constants";


const Applications = lazy(() => import("../../pages/Routes"));


const AppMain = () => {
    const token = localStorage.getItem("token");
    const [ sports, setSports ] = useState([]);
    const [ sportIds, setSportIds ] = useState({});

    const getElementBySysName = propEq("sys_name");
    const findByName = (name, data) => find(getElementBySysName(name), data);

    useAbortableEffect((status) => {
        if (token) {
            getAllSports(token)
                .then(data => {
                    if (has("data", data)) {
                        !status.aborted && setSports(data.data);

                        const soccer = findByName(SPORT_NAMES.SOCCER, data.data);
                        const basketball = findByName(SPORT_NAMES.BASKETBALL, data.data);
                        const baseball = findByName(SPORT_NAMES.BASEBALL, data.data);
                        const hockey = findByName(SPORT_NAMES.HOCKEY, data.data);
                        const golf = findByName(SPORT_NAMES.GOLF, data.data);
                        const football = findByName(SPORT_NAMES.FOOTBALL, data.data);
                        const ncaa = findByName(SPORT_NAMES.NCAA, data.data);

                        !status.aborted && setSportIds({
                            [SPORT_NAMES.SOCCER]: !!soccer ? soccer.id : null,
                            [SPORT_NAMES.BASKETBALL]: !!basketball ? basketball.id : null,
                            [SPORT_NAMES.BASEBALL]: !!baseball ? baseball.id : null,
                            [SPORT_NAMES.HOCKEY]: !!hockey ? hockey.id : null,
                            [SPORT_NAMES.GOLF]: !!golf ? golf.id : null,
                            [SPORT_NAMES.FOOTBALL]: !!football ? football.id : null,
                            [SPORT_NAMES.NCAA]: !!ncaa ? ncaa.id : null,
                        })
                    }
                })
                .catch(err => { throw new Error(err); })
        }
    }, [token]);

    return (
        <Context.Provider value={{ token, sports, sportIds }}>
            <Fragment>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse"/>
                            </div>
                        </div>
                    </div>
                }>
                    <Route path="/apps" component={Applications}/>
                </Suspense>

                <Route exact path="/" render={() => (<Redirect to={"/apps/contestdashboard"} />)}/>
                <Route exact path="/login" component={Login} />
                <ToastContainer/>
            </Fragment>
        </Context.Provider>
    )
};

export default AppMain;
