export const EVENT_TABLE_TAB_NAMES = {
    DATE: "DATE",
    TODAY: "Today",
    THIS_WEEK: "This Week",
    NFL: "NFL",
    NBA: "NBA",
    MLB: "MLB",
    NHL: "NHL",
    COLL_B_BALL: "Coll B BALL",
    COLL_FTB: "Coll FTB",
    SOCCER: "Soccer"
};

export const CREATE_STATE_KEYS = {
    DATE: "date",
    EVENT: "event",
    SPORT: "sport",
    TEAM_A: "teamA",
    TEAM_B: "teamB",
    END_TIME: "endTime",
    COUNTRY: "country",
    START_TIME: "startTime",
    COMPETITION: "competition",
};

export const EVENT_TABLE_STATE_TAB = {
    DATE: "date",
    TODAY: "today",
    THIS_WEEK: "week",
    NFL: "NFL",
    NBA: "NBA",
    MLB: "MLB",
    NHL: "NHL",
    COLL_B_BALL: "collBBall",
    COLL_FTB: "collFTB",
    SOCCER: "soccer"
};

export const EVENT_ERRORS_FIELDS = {
    START_DATE: "start_timestamp",
    END_DATE: "end_timestamp",
    COUNTRY: "competitors.first.country",
    COMPETITION: "competition",
    TEAM_A: "competitors.first.id",
    TEAM_B: "competitors.second.id",
};
