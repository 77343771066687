import React from "react";

import { Col, Row, FormGroup, Label, Input } from "reactstrap";

import PropTypes from "prop-types";

function Inputs( { inputHandler, errors } ) {
    return (
        <Row form>
            <Col md={6}>
                <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input 
                        type="email" 
                        name="email" 
                        id="exampleEmail" 
                        placeholder="Email here..."
                        invalid={!!errors && !!errors.email && !!errors.email.length}
                        onChange={inputHandler}
                    />
                    {!!errors && !!errors.email && !!errors.email.length && <span className="text-danger">{errors.email}</span> }
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label for="examplePassword">Password</Label>
                    <Input 
                        type="password" 
                        name="password" 
                        id="examplePassword" 
                        placeholder="Password here..."
                        invalid={!!errors && !!errors.password && !!errors.password.length}
                        onChange={inputHandler}
                    />
                    {!!errors && !!errors.password && !!errors.password.length && <span className="text-danger">{errors.password}</span> }
                </FormGroup>
            </Col>
        </Row>
    );
};

Inputs.propTypes = {
    inputHandler: PropTypes.func,
    errors: PropTypes.object,
};

export default Inputs;
