import React from "react";

function Welcome() {
    return (
        <div>
            <h4 className="mb-0">
                <div>Welcome back,</div>
                <span>Please sign in to your account.</span>
            </h4>
            <h6 className="mt-3">
                No account?{" "}
                <a href="/">
                    Sign up now
                </a>
            </h6>
        </div>
    );
};

export default Welcome;
