export const FIELDS_ANNOUNCEMENTS = {
    TITLE: "title",
    MESSAGE: "message",
    ENTITY_TYPE: "entity",
    ENTITY_ID: "entity_id",
    TYPE: "type"
};

export const entityForAnnouncements = [
    {value: "", name: "Type to select Entity type...", endPoint: ""},
    {value: "App\\Models\\Contest", name: "Contest", endPoint: "contests"},
    // {value: "App\\Models\\City", name: "City", endPoint: "cities"},
    // {value: "App\\Models\\Country", name: "Country", endPoint: "countries"},
    // {value: "App\\Models\\County", name: "County", endPoint: "counties"},
    // {value: "App\\Models\\State", name: "State", endPoint: "states"},
    // {value: "App\\Models\\Competition", name: "Competition", endPoint: "competitions"},
    // {value: "App\\Models\\Competitor", name: "Competitor", endPoint: "competitors"},
    // {value: "App\\Models\\College", name: "College", endPoint: "colleges"},
];
