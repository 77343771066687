import { $delete, $get } from "../functions";


export const getContests = (token, data = null) =>
    $get(`contests`, data, {token});

export const getContest = (contestId, data = null, token) =>
    $get(`contests`, data, token);

export const getCurrentContest = (contestId, data = null, token) =>
    $get(`contests/${contestId}`, data, {token});

export const deleteContest = (contestId, data = null, token) =>
    $delete(`contests/${contestId}`, data, {token});
