import React from "react";

import { Button } from "reactstrap";

function Buttons() {
    return (
        <div className="d-flex align-items-center">
            <div className="ml-auto">
            <a href="/">
                Recover Password
            </a>{" "}
            <Button color="primary" size="lg">
                Login to Dashboard
            </Button>
            </div>
        </div>
    );
};

export default Buttons;
