import { $get, $put } from "../functions";


export const getSettingsRequest = (token, body = null) =>
    $get(`settings`, body, {token});

export const getCurrentSettingsRequest = (id, token, body = null) =>
    $get(`settings/${id}`, body, {token});

export const setCurrentSettingsRequest = (id, token, body = null) =>
    $put(`settings/${id}`, body, {token});
