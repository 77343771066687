export const FIELDS_CONTESTS = {
    NAME: "name",
    TYPE: "contest_type_setting",
    STATUS: "status",
    OPEN_TIME: "open_time",
    OPEN_TIME_DATE: "open_time_date",
    OPEN_TIME_TIME: "open_time_time",
    CLOSE_TIME: "close_time",
    CLOSE_TIME_DATE: "close_time_date",
    CLOSE_TIME_TIME: "close_time_time",
    START_TIME: "start_time",
    START_TIME_DATE: "start_time_date",
    START_TIME_TIME: "start_time_time",
    END_TIME: "end_time",
    END_TIME_DATE: "end_time_date",
    END_TIME_TIME: "end_time_time",
    MAX_PLAYERS: "max_players",
    ENTRY_FEE: "entry_fee",
    ENTRY_GROUP: "entry_group",
    RANK_IDS: "rank_ids",
    QUESTIONS: "questions",
    QUESTIONS_IDS: "questions.ids",
    QUESTIONS_EVENT: "questions.event.id",
    TIE_BREAK: "tie_break_question",
    TIE_BREAK_COUNTDOWN: "tie_break_question.countdown",
    TIE_BREAK_QUESTION: "tie_break_question.question",
    TIE_BREAK_ANSWERS: "tie_break_question.answers",
    TIE_BREAK_CORRECT_ANSWER: "tie_break_question.correct_answer",
    TIE_BREAK_EVENT: "tie_break_question.event.id",
    TIE_BREAK_QUESTION_SELECT: "tie_break_question.select",
    PARTICIPATION_REWARD :"participation_reward",
    WINNER_REWARD :"winner_reward",
    NUMBER_OF_QUESTIONS: "number_of_questions",
    RANKS: "ranks",
    USE_TIE_BREAK_QUESTION: "use_tie_break_question",
    BOT_ANSWERS: "bot_answers"
};

export const noOfQuestions = [
    {value: 3, label: "3"},
    {value: 6, label: "6"},
    {value: 9, label: "9"}
];

export const entry_fee_options = [
    {value: 5, label: "5 Bolts"},
    {value: 10, label: "10 Bolts"},
    {value: 25, label: "25 Bolts"},
];

export const entry_grouping_options = [
    {value: 25, label: "25"},
    {value: 50, label: "50"},
    {value: 100, label: "100"},
    {value: 200, label: "200"},
];

export const is_tie_break_question = [
    {value: false, label: "No"},
    {value: true, label: "Yes"}
];

export const is_participation_reward = [
    {value: false, label: "No"},
    {value: true, label: "Yes"}
];
