export const FIELDS_QUESTIONS = {
    QUESTION_NAME: "name",
    ANSWERS_FIRST: "first_answer",
    ANSWERS_SECOND: "second_answer",
    DIFFICULTY: "difficulty",
    CATEGORY_ID: "category_id",
    EVENT_ID: "event_id",
    IS_AVAILABLE_FOR_CHALLENGES: "is_available_for_challenges"
};

