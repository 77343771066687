export const CREATE_COMPETITIONS_KEYS = {
    FULL_NAME: "name",
    DISPLAY_NAME: "displayName",
    SPORT: "sport",
    COUNTRY: "country",
    EVENTS: "events",
    SEASON: "season",
    ONBOARDING: "available_fon_onboarding"
};

export const CREATE_COMPETITIONS_ERROR_KEYS = {
    NAME: "name",
    SPORT: "sport_id",
    COUNTRY: "sport_category_id",
    ONBOARDING: "available_fon_onboarding"
};
