import { $delete, $get, $post } from "../functions";


export const getRewards = (token, data = null) =>
    $get(`rewards`, data, {token});

export const createReward = (token, data = null) =>
    $post(`rewards`, data, {
        token,
        formData: true,
        headers: {"Accept": "application/json"}
    });

export const editReward = (id, token, data = null) =>
    $post(`rewards/${id}`, data, {
        token,
        formData: true,
        headers: {"Accept": "application/json"}
    });

export const removeReward = (id, token, data = null) =>
    $delete(`rewards/${id}`, data, {token});
