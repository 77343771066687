export const getRequestHeaders = (method, body, ...props) => ({
    method,
    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        ...props
    },
    body,
});

