import React from "react";

import { Row, Form } from "reactstrap";

import PropTypes from "prop-types";

import Inputs from "./Inputs";
import Buttons from "./Buttons";

function LoginForm( { submitHandler, inputHandler, errors } ) {
    return (
        <Form onSubmit={submitHandler}>
            <Inputs inputHandler={inputHandler} errors={errors} />
            <Row className="divider" />
            <Buttons />
        </Form>
    );
};

LoginForm.propTypes = {
    submitHandler: PropTypes.func,
    inputHandler: PropTypes.func,
    errors: PropTypes.object,
};

export default LoginForm;
