import React, { Fragment } from "react";
import { Button } from "reactstrap";
import {Loader as LoaderAnim} from "react-loaders";


export const getButton = ({
    text,
    color = "primary",
    title = "",
    isShow = true,
    onClick = () => {},
    disabled = false,
    className = "",
    outline = false,
    active = false,
    loading = false,
    loadingColor = "#fff"
}) => (
    <>
        { isShow &&
            <Button
                title={title}
                color={color}
                onClick={onClick}
                disabled={disabled}
                className={className}
                outline={outline}
                active={active}
            >
                { loading
                    ? <LoaderAnim color={loadingColor} type={"ball-pulse"} className="text-center" />
                    : text
                }
            </Button>
        }
    </>
);

export const getButtons = (buttons) => buttons.map((item, index) => <Fragment key={index}>{getButton(item)}</Fragment>);
