export const entityForSearchPanelQuestionsManage = [
    {value: "", name: "View Questions..."},
    {value: "date", name: "By Date"},
    {value: "sport", name: "By Sport"},
    {value: "competition", name: "By League"},
    {value: "team", name: "By Team"},
    {value: "contest", name: "By Contest"},
    {value: "ended_contest", name: "By Ended Contest"}
];

export const entityForSearchPanelQuestionsManageWithoutDate = [
    {value: "", name: "View Questions..."},
    {value: "sport", name: "By Sport"},
    {value: "competition", name: "By League"},
    {value: "team", name: "By Team"},
    {value: "contest", name: "By Contest"},
    {value: "ended_contest", name: "By Ended Contest"}
];
