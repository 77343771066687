export const FIELDS_COUNTRY = {
    NAME: "name",
    IS_ENABLED: "is_enabled"
};

export const FIELDS_STATE = {
    NAME: "name",
    COUNTRY_ID: "country_id",
    COUNTRY_NAME: "country_name",
    IS_ENABLED: "is_enabled"
};

export const FIELDS_COUNTY = {
    NAME: "name",
    COUNTRY_ID: "country_id",
    COUNTRY_NAME: "country_name",
    STATE_ID: "state_id",
    STATE_NAME: "state_name",
    IS_ENABLED: "is_enabled"
};

export const FIELDS_CITY = {
    NAME: "name",
    COUNTRY_ID: "country_id",
    COUNTRY_NAME: "country_name",
    STATE_ID: "state_id",
    STATE_NAME: "state_name",
    COUNTY_ID: "county_id",
    COUNTY_NAME: "county_name",
    IS_ENABLED: "is_enabled"
};
