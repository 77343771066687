export const STATUS = {
    SCHEDULED: "scheduled",
    OPEN: "open",
    LIVE: "live",
    ENDED: "ended",
    UNUSED: "unused",
    IN_USE: "in_use",
    OFF_SEASON: "off season",
    SEASON: "season",
    ENABLED: "enabled",
    DISABLED: "disabled",
    FINISHED: "finished",
    FINISHED_AND_WAIT_GRADING: "finished_and_wait_grading"
};
