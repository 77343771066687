import { $delete, $get, $post, $put } from "../functions";


export const getNcaaCompetitions = (token, data = null) =>
    $get(`competitions/ncaa`, data, {token});

export const getNcaaCompetition = (competitionId, token, data = null) =>
    $get(`competitions/ncaa/${competitionId}`, data, {token});

export const addNcaaCompetition = (token, data) =>
    $post(`competitions/ncaa`, data, {token});

export const updateNcaaCompetition = (competitionId, token, data = null) =>
    $put(`competitions/ncaa/${competitionId}`, data, {token});

export const removeNcaaCompetition = (competitionId, token, data = null) =>
    $delete(`competitions/ncaa/${competitionId}`, data, {token});
