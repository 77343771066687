export const FIELDS_REWARDS = {
    NAME: "name",
    TYPE: "type",
    IMAGE_FILE: "image_file",
    IMAGE_LINK: "image_link",
    SPORT_ID: "sport.id",
    COLLEGE_ID: "college.id",
    COMPETITOR_ID: "competitor.id",
    COUNTRY_ID: "country.id",
    STATE_ID: "state.id",
    COUNTY_ID: "county.id",
    CITY_ID: "city.id",
    SPORT: "sport",
    COLLEGE: "college",
    COMPETITOR: "competitor",
    COUNTRY: "country",
    STATE: "state",
    COUNTY: "county",
    CITY: "city",
};

export const REWARD_TYPES  = [
    {value: "badge", name: "Badge"},
    {value: "shield", name: "Shield"},
    {value: "ribbon", name: "Ribbon"},
    {value: "cup", name: "Cup"},
    {value: "special", name: "Special"}
];
