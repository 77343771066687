import { $get, $delete, $put, $post } from "../functions";


export const getContestTypes = (token, data = null) =>
    $get(`contest-type-settings`, data, {token});

export const getContestType = (contestTypeId, token, data = null) =>
    $get(`contest-type-settings/${contestTypeId}`, data, {token});

export const removeContestType = (contestTypeId, token, data = null) =>
    $delete(`contest-type-settings/${contestTypeId}`, data, {token});

export const updateContestType = (contestTypeId, token, data = null) =>
    $put(`contest-type-settings/${contestTypeId}`, data, {token});

export const createContestType = (token, data = null) =>
    $post(`contest-type-settings`, data, {token});
