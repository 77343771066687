export const CONTEST_MANAGE_TAB_NAMES = {
    ENDING_SOON: "Ending Soon",
    TODAY: "Today",
    TOMORROW: "Tomorrow",
    THIS_WEEK: "This Week",
    NFL: "NFL",
    NBA: "NBA",
    MLB: "MLB",
    NHL: "NHL",
    SOCCER: "Soccer"
};

export const CONTEST_MANAGE_KEYS = {
    NAME: "name",
    OPEN_TIME: "open_time",
    CLOSE_TIME: "close_time",
    START_TIME: "start_time",
    END_TIME: "end_time",
    CONTEST_TYPE: "type",
    EVENTS: "events",
    CONTEST_CATEGORIES: "contest_categories",
    RANKING_LEVELS: "ranks",
    ENTRY_FEE: "entry_fee",
    ENTRY_GROUPINGS: "entry_group",
    TIE_BREAK: "tie_break_question"
};

export const SPORT_NAMES = {
    SOCCER: "soccer",
    BASKETBALL: "basketball",
    BASEBALL: "baseball",
    HOCKEY: "hockey",
    GOLF: "golf",
    FOOTBALL: "football",
    NCAA: "ncaa",
};

export const ANSWERS_CASE = {
    FIRST: "First",
    SECOND: "Second"
};
