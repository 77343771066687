import { $delete, $get, $post } from "../functions";

export const getAdminsRequest = (token, body = null) =>
    $get(`admins`, body, {token});

export const removeAdminRequest = (id, token, body = null) =>
    $delete(`admins/${id}`, body, {token});

export const toggleUserStatusRequest = (id, isEnabled, token) =>
    $post(`admins/${isEnabled ? "disable" : "enable"}`, {"ids": [id]}, {token});

export const removeAdminsRequest = (token, body = {"ids": []}) =>
    $post(`admins/delete`, body, {token});

export const toggleAdminsGroupRequest = (isEnable, token, body = {"ids": []}) =>
    $post(`admins/${isEnable ? "enable" : "disable"}`, body, {token});
