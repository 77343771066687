import { useLocation } from "react-router-dom";


export const useQuery = () => new URLSearchParams(useLocation().search);

export const getQueryString = (params) => {
    let query = "?";

    Object.keys(params).forEach((keyString) => {
        let key = keyString;

        if (params[key] !== "" && params[key] !== null && params[key] !== undefined) {
            query += `${key}=${params[key]}&`
        }
    });
    return query.slice(0, -1);
};

export const fetchQuery = (history, params) => {
    history.push(history.location.pathname + getQueryString({ ...params }));
};
