import { $delete, $get, $post, $put } from "../functions";


export const getCompetitions = (token, data = null) =>
    $get(`competitions`, data, {token});

export const getCompetition = (competitionId, token, data = null) =>
    $get(`competitions/${competitionId}`, data, {token});

export const addCompetition = (token, data) =>
    $post(`competitions`, data, {token});

export const updateCompetition = (competitionId, token, data = null) =>
    $put(`competitions/${competitionId}`, data, {token});

export const removeCompetition = (competitionId, token, data = null) =>
    $delete(`competitions/${competitionId}`, data, {token});

export const getNcaaCompetitions = (token, data = null) =>
    $get(`competitions/ncaa`, data, {token});
