export const getRequestHeadersTokenBody = (method, token, body, ...props) => ({
    method,
    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + token,
        ...props
    },
    body,
});
